/*BUTTONS*/
.btn {
	transition: all 6000 ease-in-out !important;
}
.btn-primary {
	color: #334251 !important;
	background: #00cc66 !important;
	border-radius: 20rem !important;
	border-color: #334251 !important;
	padding: 0.375rem 0.75rem; /* equivalent to px-3 py-1.5 */
	border-width: 1px;
	font-weight: 500; /* equivalent to font-medium */
	border-radius: 9999px; /* equivalent to rounded-full */
}
.btn-secondary {
	color: #334251 !important;
	background: lighten(#d0dae6, 10%) !important;
	border-color: #a8b5c5 !important;
	border-radius: 20rem !important;
	padding: 0.375rem 0.75rem;
	border-width: 1px;
	font-weight: 500;
	border-radius: 9999px;
}
.btn-sm {
	font-size: 14px !important;
	padding: 0.25rem 1rem;
}
button:disabled {
	cursor: not-allowed;
	opacity: 0.5;
	pointer-events: none;
}

a:hover {
	text-decoration: none;
	color: inherit;
}
a {
	cursor: pointer;
	color: inherit;
}

.link-primary {
	color: darken(#00cc66, 15%) !important;
	text-decoration: underline !important;
}

.link-secondary {
	color: #334251 !important;
	text-decoration: underline !important;
}

/* NOTE: NAVIGATION*/
.sublink h2 {
	color: #99a5b3;
	font-weight: 500;
}

.sublink.active h2 {
	color: #4d535a;
	color: #0c4450;
	background: linear-gradient(45deg, #09343d, #105767); /* Define the gradient colors */
	-webkit-background-clip: text; /* For WebKit (Safari, Chrome) */
	background-clip: text; /* Set background-clip to text */
	color: transparent; /* Make the text transparent */
}

.sublink div {
	background: #99a5b3;
}

.sublink.active div {
	background: #4d535a;
	background: #105767;
	background: linear-gradient(45deg, #105767, #167b91);
}

.nav-link {
	font-size: 18px;
	font-weight: 500;
	border-radius: 4px;
	background-color: none;
	border: 1px solid transparent;
	transition: background-color 0.4s ease-in-out;
	transition: outline-color 0.2s ease-in;
	opacity: 0.95;
	font-weight: 600;
}

.nav-link:hover,
.nav-link.active {
	border: 1px solid rgba(51, 66, 81, 0.1);
	color: #343a40;
	background-color: #f5f7fb !important;
	opacity: 1;
}
